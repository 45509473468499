import { ProductProjection, ProductVariant } from "@commercetools/platform-sdk"
import { Store } from "../cart/Stores"
import { CoreProductInformation } from "./models/DetailedProductInformation"
import { DomainCategory } from "./models/DomainCategory"
import { DomainVariant } from "./models/DomainVariant"

export interface ProductDalI {
  projectionById(id: string, store: Store): Promise<CoreProductInformation>
}

export interface ProductProjectionsQueryArgs {
  where: ProductProjectionQuery["where"]
  expand: string[]
  limit: number
}

export interface ProductWithCategory {
  product: CoreProductInformation
  category?: DomainCategory
  subCategory?: DomainCategory
}

export interface PaginatedResult {
  readonly count: number
  readonly total?: number
  readonly offset: number
}

export type VariantFilterFn = (variant: DomainVariant) => boolean

export type PrismicVariantFilterFn = (variant: ProductVariant) => boolean

export interface PrismicProductWithFilteredVariantsTransform {
  transform: (p: ProductProjection) => ProductProjection | undefined
}

export interface AttributeQuery {
  name: string
  value: string
}

export enum OrderBy {
  priceAsc = "priceAsc",
  priceDesc = "priceDesc"
}

export interface ProductProjectionQuery {
  isEmpty: boolean
  where: string
  variantAvailability?: string
}
