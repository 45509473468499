import { LocalizedString, ChannelReference } from "@commercetools/platform-sdk"
import { ProductUri } from "../../components/Links"
import { RecommendedLooks } from "../Recommendations"
import {
  Size,
  ModelDetail,
  KeyAndLabel,
  GarmentComposition
} from "../VariantProxy"
import { SKU } from "../VariantProxyI"
import { StockLevel } from "./DetailsPageVariant"
import { DomainCategory } from "./DomainCategory"
import { Price } from "./Price"
import { SimpleAsset } from "./SimpleAsset"

export enum ProductAttribute {
  listingLabel = "listingLabel",
  listingDescription = "listingDescription",
  detailsShortDescription = "detailsShortDescription",
  fit = "fit",
  shell = "shell",
  bestFor = "best-for",
  garmentComposition = "tpd-garment-composition",
  garmentType = "tpd-garment-type",
  garmentSubtype = "tpd-garment-subtype",
  suitableFor = "tpd-suitable-for",
  functionality = "functionality",
  layering = "layering",
  fitDescription = "fitDescription",
  washingInstructions = "tpd-washing-instructions",
  wearItWithRecommendations = "wiw-product-recommendations",
  layerItWithRecommendations = "liw-product-recommendations",
  alternativeRecommendations = "alternatives-product-recommendations",
  phoneticSpelling = "phoneticSpelling",
  style = "style"
}
export enum ColorwayAttribute {
  colorCode = "colorCode",
  colorTerm = "colorTerm",
  colorName = "colorName",
  colorHexcode = "colorHexcode",
  maleModel = "maleModel",
  femaleModel = "femaleModel",
  salesChannel = "sales-channel",
  variantAvailability = "variant-availability",
  variantDiscount = "variant-discount"
}
export enum VariantAttribute {
  size = "size"
}

export type ProductAttributes =
  | ProductAttribute
  | ColorwayAttribute
  | VariantAttribute

export interface ColorwayVariant {
  sku: SKU
  size: Size
  availability: StockLevel
  isMatchingVariant: boolean
}

export interface Facet {
  sizes: string[]
  style: string
  functionality: string[]
}

export interface BasicColorway {
  color: string
  name: string
  term: string
  hexcode: string
  assets: SimpleAsset[]
  prices: Price[]
  colorwayAvailability: string
  salesChannels: ChannelReference[]
  index: number
  inStock: boolean
}
export interface Colorway extends BasicColorway {
  modelDetail: ModelDetail[]
  variants: ColorwayVariant[]
}

export interface BasicProductInformation<C extends BasicColorway>
  extends Record<string, unknown> {
  key: string
  id: string
  version: number
  masterSku: SKU
  name: string
  description: string
  listingDescription: string
  slug: LocalizedString
  seoTitle: string
  seoDescription: string
  categories: DomainCategory[]
  colorways: Record<string, C>
  facets: Facet
  prices: Price[]
}

export const productUri = (
  product: BasicProductInformation<BasicColorway>
): ProductUri => {
  return new ProductUri(product.slug, product.key)
}

export interface CoreProductInformation
  extends BasicProductInformation<BasicColorway> {}

export interface DetailedProductInformation
  extends BasicProductInformation<Colorway> {
  attributes: {
    listingLabel?: string
    listingDescription?: string
    detailsShortDescription?: string
    fit?: unknown
    shell?: KeyAndLabel[]
    bestFor: unknown
    garmentComposition: GarmentComposition[][]
    garmentType?: unknown
    garmentSubtype?: unknown
    suitableFor?: KeyAndLabel[]
    functionality?: KeyAndLabel[]
    layering?: KeyAndLabel[]
    fitDescription?: string
    phoneticSpelling?: string
    style?: KeyAndLabel
    washingInstructions: KeyAndLabel[]
    recommendations: RecommendedLooks[]
  }
}
